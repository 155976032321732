import IconRightArrow from '@atoms/IconRightArrow';
import RemoteImage from '@atoms/RemoteImage';
import TextCurrency from '@atoms/TextCurrency';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS, AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { getAmplitudeKeyValue } from '@utils/utils';
import { Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import styles from './styles';

const logEventForPoinClick = meAmplitudeContext => {
  const event = AMPLITUDE_CONSTANTS.poin_card_clicked;
  AmplitudeHelper.logEvent(event.name, {
    [event.attributes.source]: AMPLITUDE_EVENT_SOURCES.home_page,
    ...getAmplitudeKeyValue(event.name, meAmplitudeContext),
  });
};

const AccountCard = ({ account }) => {
  const navigation = useNavigation();
  const { setSelectedBPAccounts, meAmplitudeContext } = getStore();

  // Memoize navigation and click handlers
  const handleCardPress = useMemo(
    () => () => {
      if (account.accountTypeId === 1) {
        logEventForPoinClick(meAmplitudeContext);
        setSelectedBPAccounts(account);
        navigation.navigate(navigationConstants.PembayaranTab, {
          source: AMPLITUDE_EVENT_SOURCES.poin_card_clicked,
        });
      } else if (account.accountTypeId === 0) {
        navigation.navigate(navigationConstants.SALARY_INFORMATION);
      }
    },
    [account, navigation, setSelectedBPAccounts, meAmplitudeContext],
  );

  const showRightArrow = useMemo(() => account.enableAccountCard, [account]);

  return (
    <TouchableOpacity
      style={styles.accountCardContainer}
      sentry-label={account.displayName}
      testID={`account_${account?.accountTypeId?.toString()}`}
      onPress={handleCardPress}>
      <View style={styles.iconAndAccountName}>
        <View style={styles.iconStyles}>
          <RemoteImage uri={account.icon} width={scale(15)} height={scale(15)} />
        </View>
        <Text
          alignSelf="stretch"
          flex={1}
          testID="txtAccountDisplayName"
          variant="sm-normal"
          color={colors.neutral.darkMist}>
          {account.displayName}
        </Text>
      </View>
      <View flexDirection="row" alignItems="center" marginTop="4px" marginBottom="8px">
        <TextCurrency
          testID={`txt_account_${account?.accountTypeId?.toString()}`}
          variant="md-bold"
          style={styles.accountAmount}
          showCurrencySymbol={account.accountTypeId !== 1}
          amountCurrency={account?.balance}
        />
        {showRightArrow && <IconRightArrow size={18} />}
      </View>
    </TouchableOpacity>
  );
};

AccountCard.propTypes = {
  account: PropTypes.shape({
    balance: PropTypes.number,
    icon: PropTypes.string,
    displayName: PropTypes.string,
    color: PropTypes.string,
    accountTypeId: PropTypes.number,
    enableAccountCard: PropTypes.bool,
  }),
};

AccountCard.defaultProps = {
  account: {
    icon: '',
    balance: 0,
    displayName: '',
    color: '',
    id: 0,
    enableAccountCard: false,
  },
};

export default React.memo(AccountCard);
