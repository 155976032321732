import ProfilePICBonusRow from '@atoms/ProfilePICBonusRow';
import ProfilePointsRow from '@atoms/ProfilePointsRow';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { Divider, Text, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function ProfileBonus({ showBonus }) {
  const { employeeProfile } = getStore();
  const { t } = useTranslation('common');

  // Check if the user is a PIC
  const isPIC = useMemo(() => employeeProfile?.influencerRole === 'PIC', [employeeProfile?.influencerRole]);

  return (
    <>
      <View style={appStyles.marginLeft(16)} marginY="16px">
        <Text testID="txtProfileBonusHeader" variant="md-bold">
          {t('profile.bonusHeader')}
        </Text>
        <ProfilePointsRow />
        {showBonus && isPIC && <ProfilePICBonusRow />}
      </View>
      <Divider height="4px" style={{ backgroundColor: colors.neutral.mist }} />
    </>
  );
}

ProfileBonus.propTypes = {
  showBonus: PropTypes.bool,
};

ProfileBonus.defaultProps = {
  showBonus: false,
};

export default React.memo(ProfileBonus);
