import AccountCard from '@atoms/AccountCard';
import ShimmerAccountList from '@atoms/Shimmers/ShimmerAccountList';
import SkeletonComponent from '@atoms/SkeletonComponent';
import { getStore } from '@store/storeUtils';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FlatList } from 'react-native';
import styles from './styles';

function AccountList({ isLoading }) {
  const { accounts } = getStore();

  // Memoize the accounts list for performance
  const memoizedAccounts = useMemo(() => accounts || [], [accounts]);

  if (isLoading) {
    return <ShimmerAccountList />;
  }

  if (memoizedAccounts.length > 0) {
    return (
      <FlatList
        testID="LstAccounts"
        sentry-label="LstAccounts"
        contentContainerStyle={styles.flatListParent}
        data={memoizedAccounts}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={item => item?.id?.toString()}
        renderItem={({ item }) => <AccountCard account={item} />}
      />
    );
  }

  // Default fallback if no accounts are available
  return <SkeletonComponent />;
}

AccountList.propTypes = {
  isLoading: PropTypes.bool,
};

AccountList.defaultProps = {
  isLoading: false,
};

export default React.memo(AccountList);
