import Baru from '@atoms/Loan/Baru';
import RemoteImage from '@atoms/RemoteImage';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { lockPeriodToast, unavailableToast, withdrawalSuspendedToast } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { Text, useToast } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, Image } from 'react-native';
import logTilePress from './analyticsBPTile';
import styles from './styles';
import products from '../../../product';

export function openProdOrSubProdOrNominals(productConfig, navigation, productApiData, source) {
  if (productConfig.hasSubProducts) {
    navigation.navigate(navigationConstants.SUB_PRODUCTS, { productConfig, productApiData, source });
  }
  if (productConfig.hasNominals)
    navigation.navigate(navigationConstants.NOMINALS, { productConfig, productApiData, source });
  if (productConfig.template) {
    navigation.navigate(productConfig.template, { productConfig, productApiData, source });
  }
}

function BPProductTile({ bpCategoryProducts, source }) {
  const navigation = useNavigation();
  const { isInLockPeriod, isSuspended, selectedBPAccount, validatedReferralCode } = getStore();
  const toast = useToast();
  const { t } = useTranslation('common');

  const handlePress = useCallback(() => {
    const productConfig = products[bpCategoryProducts.id];
    if (productConfig?.transaction_category_id) {
      logTilePress(productConfig, source, validatedReferralCode);
    }

    if (bpCategoryProducts.availability && bpCategoryProducts.id === -1) {
      return navigation.navigate(navigationConstants.HOME, {
        screen: navigationConstants.PembayaranTab,
        params: { source: AMPLITUDE_EVENT_SOURCES?.home_page },
      });
    }

    if (selectedBPAccount.accountTypeId === 0 && (isInLockPeriod || isSuspended) && !bpCategoryProducts.availability) {
      return isInLockPeriod ? lockPeriodToast(toast, t) : withdrawalSuspendedToast(toast, t);
    }

    if (bpCategoryProducts.id !== -1) {
      if (!bpCategoryProducts.availability) {
        return unavailableToast(toast, t);
      }
      return openProdOrSubProdOrNominals(productConfig, navigation, bpCategoryProducts, source);
    }
  }, [
    bpCategoryProducts,
    source,
    navigation,
    validatedReferralCode,
    isInLockPeriod,
    isSuspended,
    selectedBPAccount,
    toast,
    t,
  ]);

  const dynamicStyles = useMemo(
    () => ({
      tileButton: [
        appStyles.borderWithShadow,
        styles.productTileButton,
        styles.enabledDisabledStyles(bpCategoryProducts),
      ],
      opacityStyle: styles.enabledDisabledOpacity(bpCategoryProducts),
    }),
    [bpCategoryProducts],
  );

  return (
    <View style={styles.productTile}>
      <Baru
        testID={`${bpCategoryProducts.id}newShow`}
        showBaru={bpCategoryProducts.canShowNew}
        customStyles={styles.baruStyle}
        animate
      />
      <TouchableOpacity testID={`btn${bpCategoryProducts.id}`} onPress={handlePress} style={dynamicStyles.tileButton}>
        <View key={bpCategoryProducts.name} style={dynamicStyles.opacityStyle}>
          {bpCategoryProducts.id !== -1 ? (
            <RemoteImage width={32} height={32} uri={bpCategoryProducts.iconLink || bpCategoryProducts.icon} />
          ) : (
            <Image source={bpCategoryProducts.icon} style={{ width: scale(32), height: scale(32) }} />
          )}
        </View>
      </TouchableOpacity>
      <Text testID={bpCategoryProducts.screenName} style={styles.productNameText} variant="sm-normal">
        {bpCategoryProducts.screenName}
      </Text>
    </View>
  );
}

BPProductTile.propTypes = {
  source: PropTypes.string,
  bpCategoryProducts: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    iconLink: PropTypes.string,
    icon: PropTypes.string,
    screenName: PropTypes.string,
    availability: PropTypes.bool,
    canShowNew: PropTypes.bool,
  }),
};

BPProductTile.defaultProps = {
  source: '',
  bpCategoryProducts: {
    id: '',
    name: '',
    iconLink: '',
    screenName: '',
    availability: true,
    icon: '',
    canShowNew: false,
  },
};

export default React.memo(BPProductTile);
