import SkeletonComponent from '@atoms/SkeletonComponent';
import useFetchBanks from '@customHooks/useFetchBanks';
import ProfileDefaultUserBank from '@molecules/ProfileDefaultUserBank';
import ProfileViewRegisteredBankDetails from '@molecules/ProfileViewRegisteredBankDetails';
import { useIsFocused } from '@react-navigation/native';
import { AMPLITUDE_EVENT_SOURCES } from '@utils/analytics/constants';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

function ProfileBankDetails({ showBankDetails, defaultBank }) {
  const { bankName, bankAccountName, number } = defaultBank || {};
  const isFocused = useIsFocused();

  // Fetch banks using custom hook
  const { isLoading, isSuccess, bankList, refetch } = useFetchBanks();

  // Refetch bank data when the component is focused
  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused, refetch]);

  // Memoize enrolled banks for performance
  const enrolledBankList = useMemo(() => (isSuccess ? bankList : []), [isSuccess, bankList]);

  // Conditional rendering for loading state
  if (isLoading) {
    return <SkeletonComponent />;
  }

  // Conditional rendering for bank details
  if (showBankDetails) {
    if (enrolledBankList?.length > 0) {
      return (
        <ProfileViewRegisteredBankDetails
          employeeDefaultBank={defaultBank}
          source={AMPLITUDE_EVENT_SOURCES.others_page}
        />
      );
    }
    if (defaultBank) {
      return <ProfileDefaultUserBank bankName={bankName} beneficiaryName={bankAccountName} bankAccountNo={number} />;
    }
    return <SkeletonComponent />;
  }

  return null;
}

ProfileBankDetails.propTypes = {
  showBankDetails: PropTypes.bool,
  defaultBank: PropTypes.shape({
    bankName: PropTypes.string,
    bankAccountName: PropTypes.string,
    number: PropTypes.string,
  }),
};

ProfileBankDetails.defaultProps = {
  showBankDetails: false,
  defaultBank: {
    bankName: '',
    bankAccountName: '',
    number: '',
  },
};

export default React.memo(ProfileBankDetails);
