import navigationConstants from '@navigation/navigationConstants';
import { COMMON_CONSTANTS } from '@root/constants';
import colors from '@theme/colors';
import { getDateInFormatLocale, getDateInFormatLocaleSubtract } from '@utils/dateUtils';
import { Text } from 'native-base';
import React from 'react';
import { logOptInHandler, logOptOutHandler } from './analyticsAutoWithdrawal';
import styles from './styles';

export const AUTO_DISBURSE_KEYS = {
  LOCKPERIOD: 'LOCKPERIOD',
  OPT_IN: 'OPT_IN',
  OPT_OUT: 'OPT_OUT',
  SUSPENDED: 'SUSPENDED',
};
export const LOCK_PERIOD_FORMAT = 'YYYY-MM-DD';

const querybuilderTxt = 'query-builder';

export function onOptInHandler(navigation) {
  return () => {
    logOptInHandler();
    navigation?.navigate(navigationConstants.GG_AUTO_DISBURSE_TERMS);
  };
}

export function onOptOutHandler(Linking) {
  return () => {
    logOptOutHandler();
    Linking.openURL(COMMON_CONSTANTS.support);
  };
}

export const AUTO_DISBURSE_UI = {
  [AUTO_DISBURSE_KEYS.SUSPENDED]: {
    containerBackground: colors.error.softRose,
    iconName: querybuilderTxt,
    iconColor: colors.primary.carnation,
    infoText: t => t('autoDisburse.suspended'),
  },
  [AUTO_DISBURSE_KEYS.LOCKPERIOD]: {
    containerBackground: colors.error.softRose,
    iconName: querybuilderTxt,
    iconColor: colors.primary.carnation,
    infoText: t => t('autoDisburse.lockPeriod'),
  },
  [AUTO_DISBURSE_KEYS.OPT_IN]: {
    containerBackground: colors.warning.softSunflower,
    iconName: querybuilderTxt,
    iconColor: colors.warning.sunflower,
    infoText: (t, autoDisburseLoad, navigation) => (
      <Text variant="sm-bold">
        {t('autoDisburse.optIn.first')} {getDateInFormatLocale(autoDisburseLoad?.lockPeriodDate, 'DD MMMM YYYY')}{' '}
        <Text onPress={onOptInHandler(navigation)} style={styles.autoDisburseBlueLink}>
          {t('autoDisburse.optIn.linkButton')}
        </Text>
      </Text>
    ),
  },
  [AUTO_DISBURSE_KEYS.OPT_OUT]: {
    containerBackground: colors.success.softCactus,
    iconName: 'check-circle-outline',
    iconColor: colors.success.cactus,
    infoText: (t, autoDisburseLoad, _, Linking) => (
      <Text variant="sm-bold">
        {t('autoDisburse.optOut.first')}{' '}
        {getDateInFormatLocaleSubtract(autoDisburseLoad?.lockPeriodDate, 'DD MMMM YYYY')}{' '}
        <Text onPress={onOptOutHandler(Linking)} style={styles.autoDisburseRedLink}>
          {t('autoDisburse.optOut.linkButton')}
        </Text>
      </Text>
    ),
  },
};

export function showBanner(lockPeriod, isSuspended, isBillPayment, autoDisburseData, isFeaturePresent) {
  return lockPeriod || isSuspended || (autoDisburseData && isFeaturePresent && !isBillPayment);
}
