import { fetchTopProducts } from '@api/useQueryCalls';
import { lainnya } from '@assets/png/imagesPng';
import ShimmerBPTile from '@atoms/Shimmers/ShimmerBPTile';
import BPProductTile from '@molecules/BPProductTile';
import { useIsFocused } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { Text, View } from 'native-base';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import styles from './styles';

function TopProducts() {
  const { isLoading, isError, data, isSuccess, refetch } = fetchTopProducts();
  const isFocused = useIsFocused();
  const { t } = useTranslation('common');
  const sError = 'Error loading products';
  // Memoize "otherProductParams"
  const otherProductParams = useMemo(
    () => ({
      icon: lainnya,
      name: 'Layinnya',
      screenName: t('common.Layinnya'),
      id: -1,
      availability: true,
    }),
    [t],
  );

  // Refetch when screen is focused
  useEffect(() => {
    if (isFocused) refetch();
  }, [isFocused, refetch]);

  // Create product list with "otherProductParams"
  const finalProductList = useMemo(() => {
    if (isSuccess && data?.list?.length > 0) {
      const list = [...data.list];
      const otherTileExists = list.some(item => item.id === -1);
      if (!otherTileExists) {
        list.push(otherProductParams);
      }
      return list;
    }
    return [];
  }, [isSuccess, data, otherProductParams]);

  const renderProductTile = useCallback(
    ({ item }) => <BPProductTile bpCategoryProducts={item} source="home_page" />,
    [],
  );

  if (isLoading) {
    return (
      <View flexDirection="row" style={appStyles.marginHorizontal(16)}>
        {Array.from({ length: 4 }).map(key => (
          <ShimmerBPTile key={key} />
        ))}
      </View>
    );
  }

  if (isError) {
    return <Text>{sError}</Text>; // Better UX for errors
  }

  return (
    <View style={appStyles.flexRow}>
      <FlatList
        testID="fltLstTopProducts"
        style={styles.topProductsList}
        contentContainerStyle={styles.fltLstContainerStyle}
        horizontal
        data={finalProductList}
        keyExtractor={item => item?.id?.toString()}
        renderItem={renderProductTile}
      />
    </View>
  );
}

export default React.memo(TopProducts);
